/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"

import Grid from "@material-ui/core/Grid"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 750,
          padding: `0 1.0875rem 1.45rem`
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            fontFamily: "Arial, Helvetica, sansSerif",
            fontSize: "0.9rem",
            color: "#3d3d3d"
          }}
        >

        <Grid container spacing={0}>

        <Grid item xs={6}>


          <Link
            to="/about/"
            style={{ color: "#3d3d3d", textDecoration: "none" }}
          >
            {/* © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.gatsbyjs.org">Natural Afro Haire Care</a> */}
            © {new Date().getFullYear()} NAHC
          </Link>

          </Grid>



          <Grid item xs={6} style={{textAlign:"right"}}>

          <Link
            to="/privacy-policy/"
            style={{ color: "#3d3d3d", textDecoration: "none" }}
          >
            Privacy
          </Link>

          </Grid>


          </Grid>


        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
