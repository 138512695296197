import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"

import Grid from "@material-ui/core/Grid";

// Menu
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from "@material-ui/core/Button";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";

// Can't center the siteTitle vertically so -> paddingTop
// The title is out by ~2px, did this because it looks better to my eye

function Header ({ siteTitle })  
{

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
  <header
    style={{
      background: `#DDA0DD`,
      marginBottom: `1.45rem`,
      paddingBottom: '0.5rem'
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 750,
        height: 62
        //padding: `1.45rem 1.0875rem`,
      }}
    >


    <Grid
        container
        direction="row"
        spacing={0}
        justify="center"
        style={{paddingTop:"4px", paddingBottom:"4px"}}
      >


      {/* TITLE */}
      <Grid item xs={10} >
      <h1 style={{marginLeft:"18px", marginBottom:"0px", marginTop:"18px", fontSize:"1.4rem"}}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      </Grid>


      {/* MENU */}
      <Grid item xs={2} style={{textAlign: "right"}}>

      {/* <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ paddingRight: "20px", marginTop:"14px"}}
              >
                <IconButton
                  edge="start"
                  aria-label="menu"
                  style={{ color: "white" }}
                >
                  <MenuIcon />
                </IconButton>
                <p style={{color:"white", fontWeight:"400", marginBottom:"0px", paddingTop:"0px"}}>menu</p>
              </Button> */}

            <div onClick={handleClick} style={{ paddingRight: "18px", marginTop:"20px", cursor:"pointer"}}>
              <p style={{color:"white", fontWeight:"400", fontSize:"0.8rem"}}>MENU</p>
            </div>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
            
              <Link to="/#articles" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>Articles</MenuItem>
              </Link>
                
              <Link to="/#brand-reviews" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>Brand Reviews</MenuItem>
              </Link>

              <Link to="/#interviews" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>Interviews</MenuItem>
              </Link>

              <Link to="/#product-reviews" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>Product Reviews</MenuItem>
              </Link>

              <Link to="/black-owned-hair-products/#list" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>100+ Black Owned</MenuItem>
              </Link>

              <Link to="/about/" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>About</MenuItem>
              </Link>

              </Menu>
              </Grid>



      </Grid>
    </div>
  </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
